import React from 'react';
import {Box, useMediaQuery, useTheme} from "@mui/material";
import {ASSET_IMAGES} from "app/utils/constants/paths";
import {getSpacing} from "fit/system/UtilityFunctions";
import PaperMargin from "fit/components/PaperMargin";

const ExternalSystemFormWrap=(props)=>{
    const screen ={
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }

    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('md'));
    const size = smScreen ? '100%' : '40%';

    const form = {
        width: size,
    }
    /*
    <div style={{textAlign: 'center'}}>
                        <Logo/>
                    </div>
                    <div style={{marginTop: getSpacing()}}>
                        {props.children}
                    </div>


    */
    return (
        <Box sx={screen}>
            <Box sx={form}>
                <PaperMargin>
                    <center><img src={`${ASSET_IMAGES}/logo.png`} alt={'Buteo Logo'} style={{marginBottom: getSpacing()}}/></center>
                    {props.children}
                </PaperMargin>
            </Box>
        </Box>
    );

}

export default ExternalSystemFormWrap;