import React from 'react';
import {Typography, Grid, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, useTheme} from "@mui/material";
import UserPreferences from "fit/system/UserPreferences";


const MeasurementUnitPreferences=({massUnit, smallLength, mediumLength, longLength, onChange, headerVariant})=>{
    const variant = headerVariant ? headerVariant : 'h3';
    const theme = useTheme();
    const secondaryColor = theme.palette.secondary.main;
    const userPrefs = new UserPreferences(secondaryColor);
    const {formats} = userPrefs;
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant={'h2'}>Measurement Unit Preferences</Typography>
                <Typography variant={'body1'}>
                    Buteo will convert measurements to units that you're familiar with.
                </Typography>
            </Grid>
            {/*
            <Grid item xs={12}>
                <Typography variant={variant}>Measurement Units: Metric/Imperial</Typography>
                <FormControl>
                    <FormLabel>Measurement Unit Preferences</FormLabel>
                    <RadioGroup>
                        {formats.unitTypes.map((item,k) =>{
                            const checked = parseInt(unitType) === parseInt(item.id);
                            return <FormControlLabel key={k} control={<Radio checked={checked} onChange={()=>onChange('unitType', item.id)}/>} label={item.label}/>
                        })}
                    </RadioGroup>
                </FormControl>
            </Grid>
            */}
            <Grid item xs={12}>
                <Typography variant={variant}>Mass Units</Typography>
                <FormControl>
                    <FormLabel>How should mass measurements be displayed?</FormLabel>
                    <RadioGroup>
                        {formats.massUnits.map((item,k) =>{
                            const checked = parseInt(massUnit) === parseInt(item.unitID);
                            return <FormControlLabel key={k} control={<Radio checked={checked} onChange={()=>onChange('massUnit', item.unitID)}/>} label={item.label}/>
                        })}
                    </RadioGroup>
                </FormControl>

            </Grid>
            <Grid item xs={12}>
                <Typography variant={variant}>Small Distances/Lengths Units</Typography>
                <FormControl>
                    <FormLabel>How should "small" distances or lengths be displayed?</FormLabel>
                    <RadioGroup>
                        {formats.smallLengthUnits.map((item,k) =>{
                            const checked = parseInt(smallLength) === parseInt(item.unitID);
                            return <FormControlLabel key={k} control={<Radio checked={checked} onChange={()=>onChange('smallLength', item.unitID)}/>} label={item.label}/>
                        })}
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Typography variant={variant}>Medium Length/Distance Units</Typography>
                <FormControl>
                    <FormLabel>How should "medium" distances or lengths be displayed?</FormLabel>
                    <RadioGroup>
                        {formats.mediumLengthUnits.map((item,k) =>{
                            const checked = parseInt(mediumLength) === parseInt(item.unitID);
                            return <FormControlLabel key={k} control={<Radio checked={checked} onChange={()=>onChange('mediumLength', item.unitID)}/>} label={item.label}/>
                        })}
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Typography variant={variant}>Large Length/Distance Units</Typography>
                <FormControl>
                    <FormLabel>How should "large" distances or lengths be displayed?</FormLabel>
                    <RadioGroup>
                        {formats.longLengthUnits.map((item,k) =>{
                            const checked = parseInt(longLength) === parseInt(item.unitID);
                            return <FormControlLabel key={k} control={<Radio checked={checked} onChange={()=>onChange('longLength', item.unitID)}/>} label={item.label}/>
                        })}
                    </RadioGroup>
                </FormControl>
            </Grid>
        </Grid>
    );
}
export default MeasurementUnitPreferences;