import React from 'react';
import {CircularProgress} from "@mui/material";
import PaperMargin from "../PaperMargin";
import Header from "../Header";
import TabSection from "../TabSection";

const ButeoSuspenseFallback=()=>{
    const centerCSS = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    }

    return (
        <div style={{marginTop: '30px'}}>
            <Header header={' '}/>
            <PaperMargin>
                <TabSection style={centerCSS}>
                    <CircularProgress sx={{m: '-40px auto 0'}}/>
                </TabSection>
            </PaperMargin>

        </div>
    )
}

export default ButeoSuspenseFallback;