/*
	FITUSER REDUCER FOR MANAGING USER SETTINGS
*/

const startingUserSettings = {
	authorized: false, //is logged in
	pending: 0, //pending session check (0=> not checked, 1=>in progress, 2=>Complete)
	redirect: '',  //route to redirect user depending on authorization
	online: true, //connection active
	pc: false, //pending complete (bool)
	account: {}, //AIT User Info (Settings/Permissions)
};


const FITUser = (state = startingUserSettings, action) =>{
	switch(action.type){
		case 'UPDATE_USER':
			//Update user permissions occurs when user account is acted on
			return {
				...state,
				authorized: action.authorized,
				pending: 0,
				pc: true, //pending complete
				account: action.userInfo,
			};
		case 'SET_ONLINE':
			//Updates the state. Tells whether the app is online or offline.
			return {
				...state,
				online: action.online
			}
		case 'UPDATE_USER_DETAILS':
			//Update user details (name, phone, email, mailing address) when user edits their own account
			return {
				...state,
				account: action.userInfo
			};
		case 'LOGOUT':
			return {
				...state,
				authorized: false,
				pending: 2,
				pc: true,
				account: {}
			};
		case 'LOCK_SCREEN':
			return {
				...state,
				authorized: false,
				pending: 2,
				pc: true,
			};
		case 'SET_PENDING':
			let pcStatus = action.pendingStatus > 1 ;
			return {
				...state,
				authorized: false,
				pc: pcStatus,
				pending: action.pendingStatus,
			};
		case 'SET_REDIRECT_URL':
			return {
				...state,
				redirect: action.url
			};
		default:
			return state;
	}
};

export default FITUser;
