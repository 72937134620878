import React from 'react';
import {Paper} from "@mui/material";
import {getSpacing} from "fit/system/UtilityFunctions";

const PaperMargin=({small = false, sx, children})=>{
    const componentSX = {
        p: {
            lg: small ? getSpacing('small') : getSpacing(),
            xs: getSpacing('iconAlignment'),
        },
        ...sx ?? {}
    }
    return (
        <Paper sx={componentSX}>
            {children}
        </Paper>
    )
}

export default PaperMargin;