import React from 'react';
import {ListItemIcon, ListItemText, ThemeProvider, Typography} from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import LogoutIcon from '@mui/icons-material/Logout';
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings"
import {useNavigate} from "react-router-dom";
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import Div from "@jumbo/shared/Div";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";

import User from 'fit/system/User';
import UserPreferences from "fit/system/UserPreferences";
import {connect} from 'react-redux';
import {AccountCircle, TabletAndroid, Person} from '@mui/icons-material';
import {URL_LOGIN, URL_MY_PROFILE} from "fit/system/FITConstants";

const AuthUserDropdown = ({openAdminModalFn, loginModalFn, scrolled}) => {
    const navigate = useNavigate();
    const {theme} = useJumboTheme();
    const user = new User();
    const deviceAccount = user.getDeviceAccount();
    const deviceMaster = user.getDeviceMaster();
    const userInfo = user.getUserInfo();
    const altSet = user.getDeviceSubAccount() != null;
    const userName = `${userInfo.firstName} ${userInfo.lastName}`;
    const userPrefs = new UserPreferences();
    const allowAltAccounts = userPrefs.allowAltAccounts != null && parseInt(userPrefs.allowAltAccounts) !== 0;
    const standardButtonSize = 48;
    const scrollButtonSize = standardButtonSize/2;
    const buttonSize = scrolled ? scrollButtonSize : standardButtonSize;
    const iconSettings = {width: 60, height: 60, mb: 2}
    const buttonSettings = {cursor: 'pointer', width: buttonSize, height: buttonSize, color: theme.palette.primary.contrastText}
    const icon = deviceAccount ? <TabletAndroid sx={iconSettings}/> : <AccountCircle sx={iconSettings}/>;
    const button = deviceAccount ? <TabletAndroid sx={buttonSettings}/> : <AccountCircle sx={buttonSettings}/>;
    const iconSX = {minWidth: 36};
    const sxWhat = {my: 0};
    return (
        <ThemeProvider theme={theme}>
            <JumboDdPopover triggerButton={button}>
                <Div sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    minWidth: '120px',
                    p: theme => theme.spacing(2.5),
                }}>
                    {icon}
                    <Typography variant={"h5"}>{userName}</Typography>
                    {
                        !deviceAccount || deviceMaster ?
                            <Typography variant={"body1"} color="text.secondary">{userInfo.email}</Typography>
                            :null
                    }

                </Div>
                <Divider/>
                <nav>
                    <List disablePadding dense={false} sx={{pb: 1}}>

                        {
                            deviceAccount && !deviceMaster ?
                                <React.Fragment>
                                    {allowAltAccounts ?
                                        <ListItemButton onClick={()=>loginModalFn()} disabled={altSet}>
                                            <ListItemIcon sx={iconSX}>
                                                <Person/>
                                            </ListItemIcon>
                                            <ListItemText primary={'Sign In'} sx={sxWhat}/>
                                        </ListItemButton>
                                        :
                                        null
                                    }

                                    <ListItemButton onClick={()=>openAdminModalFn()}>
                                        <ListItemIcon sx={iconSX}>
                                            <AdminPanelSettingsIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary={'Admin'} sx={sxWhat}/>
                                    </ListItemButton>

                                    {/*
                                    <ListItemButton onClick={()=>{
                                        user.logout();
                                        navigate(URL_LOGIN);
                                    }}>
                                        <ListItemIcon sx={iconSX}>
                                            <LogoutIcon/>
                                        </ListItemIcon>
                                        <ListItemText
                                            primary="Logout"
                                            sx={sxWhat}
                                        />
                                    </ListItemButton>
                                    */}

                                </React.Fragment>

                                :
                            <React.Fragment>

                                { !deviceAccount ?
                                    <ListItemButton onClick={()=>navigate(URL_MY_PROFILE)}>
                                        <ListItemIcon sx={iconSX}>
                                            <Person/>
                                        </ListItemIcon>
                                        <ListItemText primary="Profile" sx={sxWhat}/>
                                    </ListItemButton>
                                    :
                                    null
                                }

                                <ListItemButton onClick={()=>{
                                    user.logout();
                                    navigate(URL_LOGIN);
                                }}>
                                    <ListItemIcon sx={iconSX}>
                                        <LogoutIcon/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Logout"
                                        sx={sxWhat}
                                    />
                                </ListItemButton>
                            </React.Fragment>
                        }


                        {/*
                            <ListItemButton>
                                <ListItemIcon sx={{minWidth: 36}}>
                                    <RepeatOutlinedIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    onClick={() => navigate("/switch-user/")}
                                    primary="Switch User"
                                    sx={sxWhat}
                                />
                            </ListItemButton>
                        */}

                    </List>
                </nav>
            </JumboDdPopover>
        </ThemeProvider>
    );
};

const mapStateToProps=(state)=>{
    return {FITUser: state.FITUser};
}

export default connect(mapStateToProps)(AuthUserDropdown);
