import React from 'react';
import {Typography, Grid, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel} from "@mui/material";
import UserPreferences from "fit/system/UserPreferences";


const PrivacySettings=({headerVariant})=>{
    const variant = headerVariant ? headerVariant : 'h3';
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ul>
                    <li>Necessary Note/Reminder: Your Coaches and Superiors will be still be able to find you through their organization's directories</li>
                    <li>Intro text summary something like: These settings protect your information from "outsiders" or people outside your hierarchical structure</li>
                    <li>Enable Public Profile<ul>
                        <li>Display Profile Picture to outsiders: 1/0</li>
                        <li>Display Personal Stats <em>(Allow people to choose which stats they show)</em></li>
                        <li>Maybe eventual videos/highlight reels for stats, athletic performances? (Mini scouting profile)</li>
                        <li>Education, History (similar to linked-in)<ul>
                            <li>This will be necessary for Coaches (both sport specific & strength and conditioning)</li>
                            <li>Display Certifications, education, background, masters..etc</li>
                        </ul></li>
                    </ul></li>
                    <li>User Directory Settings<ul>
                        <li>Global User Directory Setting: 1/0</li>
                        <li>Scouting/Athletic Performance Directory (for athletes): 1/0 (Is this the same thing as above? consolidate?)</li>
                        <li>How people can find you:<ul>
                            <li>Name</li>
                            <li>Phone</li>
                            <li>Email</li>
                            <li>Sports, KPI's, other pii, etc</li>
                        </ul></li>
                    </ul></li>
                    <li>Market Place settings:<ul>
                        <li>Figure out what data gets shared with coaches show sell workouts</li>
                        <li>Streamline or provide more interactivity between coaches and athletes</li>
                    </ul></li>
                </ul>
            </Grid>
        </Grid>
    )

}

export default PrivacySettings;