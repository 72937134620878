import React, {useState, useEffect} from 'react';
import Alert from "../Alert";
import User from 'fit/system/User';
import {getSpacing} from "fit/system/UtilityFunctions";
import {connect} from "react-redux";

const ConnectionErrorAlert=()=>{
    const [display, setDisplay] = useState(true);
    const user = new User();
    const store = user.getFitUserStore();
    const {online} = store;

    //Listen for online status
    useEffect(()=>{
        window.addEventListener('offline', ()=> {
            user.setOnlineStatus(false);
        });
        window.addEventListener('online', ()=>{
            user.setOnlineStatus(true);
        })
    },[]);


    return (
        <Alert
            status={'warning'}
            visible={display && !online}
            style={{marginBottom: getSpacing('small')}}
            headline={'Experiencing Internet Connectivity Problems'}
            message={"Buteo currently has trouble accessing the internet. Some features will be inaccessible or disabled."}
        />
    )
}


const mapStateToProps=(state)=>{
    return {FITUser: state.FITUser};
}

export default connect(mapStateToProps)(ConnectionErrorAlert);