import React,{useState, useEffect, useRef} from 'react';
import QRCode from 'qrcode';
import {Button, Typography, Collapse, Paper, LinearProgress} from "@mui/material";
import {Cancel, QrCode} from "@mui/icons-material";
import SecureConnect from "fit/system/SecureConnect";
import {getSpacing} from "fit/system/UtilityFunctions";
import Alert from "../Alert";


const DEVICE_STORAGE_LOCATION = '_DeviceLogin';

const initState =()=>{
    //const storage = localStorage.getItem(DEVICE_STORAGE_LOCATION);
    return {
        loginCode : '',
        requested: false,
        expiry: null,
        timestamp: null,
        lifetime: 0
    }
}


const DeviceLogin=({altAccount = false, requestCode = false, successFn})=>{
    const [state, setState] = useState(initState);
    const [success, setSuccess] = useState(false);
    const [initialized, setInitialized] = useState(false); //hide the button until fully loaded
    const externalRequest = !altAccount;
    const QRTarget = useRef(null);
    let pollInterval;

    //Initialize (hide the request button until fully loaded)
    useEffect(()=>{
        setInitialized(true);
    },[])

    //Request automatically if altAccount is True
    useEffect(()=>{
        if(requestCode && !state.requested){
            requestKey();
        }
        if(altAccount && !requestCode){
            reset();
        }

    }, [altAccount, requestCode]);


    //Start polling deviceLogin once requested
    useEffect(()=>{
        if(state.requested) {
            pollInterval = setInterval(() =>{
                checkKey();
            }
            , 5000);
            return () => clearInterval(pollInterval);
        }
    },[state.requested]);

    useEffect(()=>{
        //Device login complete
        //handle logic here
        if(success) {
            if(successFn != null){
                successFn()
            }
        }
    },[success])


    const requestKey=()=>{
        console.log('REQUESTING');
        const sc = new SecureConnect('system.php', 'post');
        sc.setAction('requestDeviceLogin');
        const location = altAccount ? 'ext' : 'int';
        const alt = altAccount ? 1 : 0;
        sc.setFormData({location, alt});
        sc.connect().then(json=>{
            console.log(json);
            if(sc.getCompleted(json)){
                const data = sc.getData(json);
                const state = {
                    loginCode: data.qrCode,
                    expiry: data.expiry,
                    timestamp: data.timestamp,
                    lifetime: data.lifetime,
                    requested: true,
                }
                setState(state);
                setInitialized(true);
            }
        })
    }
    const checkKey=()=>{
        const sc = new SecureConnect(`system.php?action=pollDeviceLogin&code=${state.loginCode}`);
        sc.setDisplayNotifications(false);
        sc.connect().then(json =>{
            if(sc.getCompleted(json)){
                //MORE SUCCESS CODE HERE
                console.log('COMPLETED');
                setSuccess(true);
                reset();
                return clearInterval(pollInterval);

            }
            //Update timer with # of seconds left
            const now = Math.floor(Date.now()/1000)
            const timeRemaining = state.expiry - now;
            //Time ran out
            if(timeRemaining <= 0){
                clearInterval(pollInterval);
                setState(initState);
            }
        })
    }

    const reset=()=>{
        setState(initState);
        clearInterval(pollInterval);
    }

    const {requested, loginCode} = state;
    const canvasDim = 280;
    const boxDim = `${canvasDim+6}px`;
    const target = QRTarget.current;
    if(requested && loginCode.length > 0){
        const qrOptions = {
            errorCorrectionLevel: 'H',
            width: canvasDim
        }
        QRCode.toCanvas(target, loginCode, qrOptions, (error)=>{
            if(error){
                console.error(error)
            }
        })
    } else{
        if(target != null) {
            const context = target.getContext('2d');
            context.clearRect(0, 0, target.width, target.height);
        }
    }

    const flexContainerCss = {
        height: '100%',
        padding: 0,
        margin: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
    const paperSx = {
        width: boxDim,
        height: boxDim,
    }
    return (
        <div>
            <div style={flexContainerCss}>
                <div>
                    {externalRequest ?
                        <Typography variant={'h1'} sx={{textAlign: 'center'}}>Device Login</Typography>
                        : null
                    }
                <Paper sx={paperSx}>
                    <canvas ref={QRTarget} width={canvasDim} height={canvasDim}></canvas>
                </Paper>


                <div style={{marginTop: getSpacing()}}>
                    <Alert
                        status={'success'}
                        visible={success}
                        headline={'Login Successful'}
                        message={'More logic will be built out...eventually.'}
                    >
                        <Button variant={'outlined'} onClick={()=>setSuccess(false)}>Reset</Button>
                    </Alert>
                    <Collapse in={initialized && !requested}>
                        <Button
                            fullWidth
                            variant={'contained'}
                            color={'primary'}
                            onClick={()=>requestKey()}
                        ><QrCode/> Request Login</Button>
                    </Collapse>
                    <Collapse in={requested && !success}>
                        <LinearProgress variant={'indeterminate'}/>
                        <Typography
                            variant={'body1'}
                            sx={{textAlign: 'center', marginTop: getSpacing('small')}}
                        >
                            Scan The QR code to log into this device.
                            <br/>This code is good for 5 minutes.
                        </Typography>
                        <Button
                            sx={{marginTop: getSpacing()}}
                            fullWidth
                            color={'secondary'}
                            variant={'contained'}
                            onClick={()=>reset()}
                        ><Cancel/> Cancel</Button>
                    </Collapse>
                </div>
                </div>
            </div>
        </div>
    )
}

export default DeviceLogin;