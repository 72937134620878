import React from 'react';
import TabSection from "../TabSection";

const TermsAndConditions=()=>{
    return (
        <div>
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
            <br/>Scrolling Terms & Conditions here
        </div>
    )
}

export default TermsAndConditions;

