import React from 'react';
import {getSpacing} from "fit/system/UtilityFunctions";
import {DESKTOP_MIN_HEIGHT} from "fit/system/FITConstants";

const TabSection=({children, paddingTop = true, style})=>{
    const paddingSize = paddingTop ? getSpacing() : '0px';
    return(
        <div style={{paddingTop: paddingSize, minHeight: DESKTOP_MIN_HEIGHT, ...style}}>
            {children}
        </div>
    )
}

export default TabSection;