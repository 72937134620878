import SecureConnect from 'fit/system/SecureConnect';
import ClientStore from './ClientStore'; //AIT
//import ColorThemes from "./ColorThemes"; //AIT
//import {DefaultTheme} from "./ColorThemes"; //AIT
import {
	PERM_SET,
	PERM_TEAM,
	PERM_DEPT,
	ASSISTANT_LEVEL,
	ATHLETE_DEPT_ID,
	COACHING_STAFF_DEPT_ID,
	SESSION_TIME_STRING,
	STRENGTH_AND_CONDITIONING_DEPT_ID,
	DEPT_MANAGER_LABELS,
	ATHLETE_MANAGER_LABELS,
	DEVICE_MASTER_ACCOUNT_VALUE,
	BUTEO_BROADCAST_CHANNEL
} from "./FITConstants";
import {isEmpty} from "./UtilityFunctions";
import Theme from './Theme';
import {
	updateUser,
	updateUserDetails,
	setPending,
	setRedirectURL,
	setOnline,
	setThemeColor,
	setMeasurementUnits
} from 'app/redux/actions';

export class User{
	constructor(logConsole = false){
		this.checkType = '';
		this.checkID = 0;
		this.strict = false;
		this.logConsole = logConsole; //logConsole;
		this.redirectPath = '';
		this.redirectComplete = true;

		//Connection State Strings
		this.CSRF_TOKEN = 'CSRF_TOKEN';

		this.appStatePermissionString = 'appState';
		this.serverAPIKey = 'API_KEY';
		this.API_KEY = 'BUTEO_API_KEY';

		this.mUnitString = 'mUnits'; //Where measurement units are stored in local storage
		this.timeoutCheckSeconds = 50; //Timeout before contacting the server for an authorization refresh
		this.connectionStorage = '__UserConnection';
		this.startingConnectState = {
			//Has the user been previously authorized
			//used when the browser refreshes and the appstate is temporarily lost
			authorized: 0,
			//Last time client connected to the server
			[SESSION_TIME_STRING]: 0,
			//how often the server/system refreshes the user/cookie/token (seconds)
			refreshTime: 0,
			//bool on whether a connection is active
			connecting: 0,
			//Approximate time in UnixTime milliseconds when the server will update (based on refreshTime*1000 + unix time when last updated)
			nextUpdate: 0,
			//whether the client is updating the user's state (updating the cookie/csrf token)
			updating: 0,
			//unix time for when the updating status was changed, failsafe to override updating status/value
			updateTimestamp: 0,
			//CSRF TOKEN RECEIVED FROM THE SERVER
			CSRF_TOKEN: '',
			//how many attempts have failed: Reset on successful connection
			//used for managing internet connectivity status
			failedAttempts: 0,

		};
	}
	logout(connect = true){
		this.setClientStorePermissions(false,{});
		const sc = new SecureConnect('system.php', 'post');
		sc.setAction('logout');
		sc.setFormData({userID: this.getUserID()})
		sc.setDisplaySuccessMessages(false);
		sc.setDisplayNotifications(false);
		if(connect) {
			sc.connect();
			//Broadcast logout to all other tabs
			const bc = new BroadcastChannel(BUTEO_BROADCAST_CHANNEL);
			bc.postMessage({reload: true});
		}
		//this.setThemeColor(DefaultTheme); __AIT__DELETE
		//Terminate storage
		//console.log('TERMINATING STORAGE');console.log(Object.keys(localStorage));
		Object.keys(localStorage).forEach(key=> {
			localStorage.removeItem(key);
		});
		Object.keys(sessionStorage).forEach(key =>{
			sessionStorage.removeItem(key);
		});
		this.setAuthorizedStatus(false);
		this.setAppState({});


	}
	logoutAlternateAccount(){
		const sc = new SecureConnect('devices.php', 'post');
		sc.setDisplayNotifications(false);
		sc.setAction('logoutAlt');
		sc.setFormData({logout: 1});
		sc.connect();
	}
	getOnlineStatus(){
		return this.getFitUserStore().online;
	}
	setOnlineStatus(bool){
		ClientStore.dispatch(setOnline(bool));
	}
	testSWConfirm(){
		const channel = new BroadcastChannel(BUTEO_BROADCAST_CHANNEL);
		channel.postMessage({confirmServiceWorkerReload: true});
	}
	setClientStorePermissions(authorized, json = {}){
		//Establish person's authorization & permissions for system access
		//Set the store to match server permissions

		//STORE THE API KEY
		if(false) {
			console.log('***************************************************************************************************');
			const currentToken = this.getCSRFToken();
			//console.log('CURRENT CSRF', currentToken);
			//console.log('NEW CSRF', json[this.CSRF_TOKEN]);
			console.log('CSRF TEST', json.CSRF_TOKEN === currentToken, json.CSRF_TOKEN);
			console.log(authorized, 'UPDATING PERMISSIONS', json);
			console.log('***************************************************************************************************');
		}
		//Update connection state:
		const csrf = authorized ? json.CSRF_TOKEN : '';
		const refreshTime = authorized ? json.refreshTime : 0;
		this.setAuthorizedStatus(authorized, csrf, refreshTime, 0);


		//Send to Redux/State
		//Check/Maintain User Preferences when updating user/state for authorized users
		if (authorized && json.userInfo && json.userInfo.preferences == null) {
			//Preferences doesn't exist when permissions gets updated
			//Pull From storage and restore
			const currentState = this.getAppState();
			const {preferences} = currentState.userInfo;
			json.userInfo.preferences = preferences;
		}

		this.setAppState(json);
		ClientStore.dispatch(updateUser(authorized, json));
		if(json.userInfo != null && json.userInfo.themeColor != null){
			const themeColor = json.userInfo.themeColor;
			this.setThemeColor(themeColor);
		}
	}
	updatePreferences(fullPreferences, onboardComplete = false){
		let {account} = this.getFitUserStore();
		console.log('PREFERENCES', fullPreferences);

		account.userInfo.preferences = fullPreferences;
		if(onboardComplete) {
			account.userInfo.onboarded = 1;
		}

		this.setClientState(account);

		console.log('************* UPDATING STATE ********************');
		console.log('PREFERENCES', fullPreferences);
		console.log('STATE', account);
		console.log('************* CURRENT STATE ********************');
		console.log(this.getAppState());

	}
	updatePreference(preference, value){
		//Update Local Storage Preferences with new preferences
		//submitted after onboarding
		let state = this.getFitUserStore();
		let account = state.account;
		account.userInfo.preferences[preference] = value;

		this.setClientState(account);

		/*
		console.log('************* UPDATING STATE ********************');
		console.log(account);
		console.log('PREFS');
		console.log(account.userInfo.preferences);
		console.log('*************************************************')
		*/
	}
	setMeasurementData(data){
		const hasUnits = data.measurementUnits != null && !isEmpty(data.measurementUnits);
		if(hasUnits){
			const mUnits = data.measurementUnits;
			this.setClientStateMeasurementData(mUnits)
			this.storeLocalMeasurementUnits(mUnits);
		}
	}
	setClientState(data){
		if(this.logConsole){
			console.log('Setting Client State');
		}
		//When the user updates there details (name, phone, email, mailing address)
		//Update the interface state
		ClientStore.dispatch(updateUserDetails(data));
		this.setAppState(data);
	}
	setThemeColor(colorID){
		if(colorID === false || colorID == null || colorID === '' || isNaN(colorID)){
			//Die early
			return;
		}
		const theme = new Theme();
		const statusColors = theme.getStatusColors(colorID);
		ClientStore.dispatch(setThemeColor(colorID, statusColors));
		/*
		const statusColors = {} //__AIT__REFACTOR :: ColorThemes[colorCode].palette.statuses || {};
		const body = document.body.classList;
		const themes = []; //__AIT__REFACTOR :: Object.keys(ColorThemes);
		if(colorCode === 'dark-theme'){
			ClientStore.dispatch(setDarkTheme(statusColors));
		} else{

		}
		//Remove the themes
		themes.forEach(theme=>{
			body.remove(theme);
		});
		//Add theme
		body.add(colorCode);

		 */
	}
	setAuthorizedStatus(loggedIn, csrf = '', refreshTime, updating = 0){
		if(!loggedIn){
			this.initUserConnectionState();
		} else{
			const now = Date.now();
			const nextUpdate = now + (parseInt(refreshTime) * 1000);
			const connectionState = {
				authorized: 1,
				refreshTime,
				connecting: 0,
				nextUpdate,
				updating,
				updateTimestamp: now,
				CSRF_TOKEN: csrf,
			}
			console.log('*********************************************************');
			console.log('NEW CONNECTION STATE', connectionState);
			this.setUserConnectionState(connectionState);
		}
	}
	setAppState(json){
		localStorage.setItem(this.appStatePermissionString, JSON.stringify(json));
	}
	getAppState(){
		return JSON.parse(localStorage.getItem(this.appStatePermissionString));
	}
	storeLocalMeasurementUnits(units){
		localStorage.setItem(this.mUnitString, JSON.stringify(units));
	}
	getPersistStatusCheck(){
		return parseInt(localStorage.getItem('pendingPersistStatusCheck'));
	}
	getAPIKey(){
		const key = localStorage.getItem(this.API_KEY);
		return key ? key : '';
	}
	getCSRFToken(){
		const state = this.getUserConnectionState();
		return state.CSRF_TOKEN;
	}
	initUserConnectionState(){
		localStorage.setItem(this.connectionStorage, JSON.stringify(this.startingConnectState));
	}
	setUserConnectionState(newSettings){
		let state = this.getUserConnectionState();
		const newState = {...state, ...newSettings};
		localStorage.setItem(this.connectionStorage, JSON.stringify(newState));
	}
	getUserConnectionState(){
		const string = localStorage.getItem(this.connectionStorage);
		return string && string !== '' ? JSON.parse(string) : this.startingConnectState;
	}
	storeAPIKey(APIKey){
		if(APIKey != null && APIKey.length) {
			console.log('STORING API KEY', this.API_KEY, ':', APIKey);
			localStorage.setItem(this.API_KEY, APIKey);
		}
	}
	setPersistStatusCheck(pendingValue){
		const storedValue = pendingValue ? 1:0;
		localStorage.setItem('pendingPersistStatusCheck', storedValue);
	}
	checkLocalStorageAuthorizeStatus(){

		const {authorized} = this.getUserConnectionState();
		return authorized === 1;


		//const localStorageAuthorizationValue = localStorage.getItem(this.authString);
		//return localStorageAuthorizationValue != null && parseInt(localStorageAuthorizationValue) === 1;
	}
	persistClientState(url = ''){
		//persists the client's state on refresh
		//Refreshing the page will nullify the user account
		//pulls appState from localstorage status and updatesUser/App
		const time = parseInt(localStorage.getItem(SESSION_TIME_STRING));
		const now = parseInt(Date.now()/1000);
		const withinTimeFrame = true; //now < time+this.timeoutCheckSeconds;
		const locallyAuthorized = this.checkLocalStorageAuthorizeStatus();
		const userData = locallyAuthorized ? this.getAppState() : {};
		const validStorageSession = userData.deptPermissions != null && !isEmpty(userData.deptPermissions) && userData.userInfo != null && !isEmpty(userData.userInfo);

		if(false){
			console.log('------------------------User.js.persistClientState()------------------------');
			console.log('Locally Authorized', locallyAuthorized);
			console.log('USER DATA', userData);
			//console.log('LOGGED IN STATUS', loggedInStatus);
			console.log('VALID STORAGE SESSION ?', validStorageSession);
			console.log('-----------------------/User.js.persistClientState()------------------------');
		}

		//Set to AppState
		if(validStorageSession) {
			this.setClientStorePermissions(locallyAuthorized, userData);
			//Store the units
			const mUnits = JSON.parse(localStorage.getItem(this.mUnitString));
			this.setClientStateMeasurementData(mUnits);
			//temporarily load sessions, contact the server to verify
			if(!withinTimeFrame){
				console.log('NO LONGER WITHIN TIMEFRAME, CHECKING SESSON');
				this.checkSession();
			}
			if (url !== '') {
				//Redirect the user to the proper address
				//this.setRedirectURL(url);
			}
		} else{
			//Data likely corrupted. Force user to log back in.
			this.logout(false);
		}
	}
	setClientStateMeasurementData(unitData){
		ClientStore.dispatch(setMeasurementUnits(unitData));
	}
	updateSessionPending(pendingValue){
		const store = this.getStore();
		if(store.FITUser != null && store.FITUser.pending < pendingValue){
			ClientStore.dispatch(setPending(pendingValue));
		}
	}
	setRedirect(url, complete = false){
		this.redirectComplete = complete;
		this.redirectPath = url;
	}
	getRedirect(){
		return {
			redirectPath: this.redirectPath,
			redirectComplete: this.redirectComplete
		}
	}
	setRedirectURL(url = false){
		const store = this.getStore();
		if(store.FITUser != null){
			url = url !== false && url != null ? url : '';
			ClientStore.dispatch(setRedirectURL(url));
		}
	}
	checkCoreSuperUser(){
		return false;
	}
	async checkSession(){
		const sc = new SecureConnect('system.php?action=authUser','get');
		sc.setDisplaySuccessMessages(false);
		sc.setDisplayNotifications(false);
		this.updateSessionPending(1);
		sc.connect().then(jsonResponse =>{
			//1b. Check completed (user logged in)
			console.log('CHECKING SESSION', jsonResponse);
			if(sc.getCompleted(jsonResponse)){
				console.log('SESSION OK');
				//1c. Assign team assignments, permissions, user settings to redux
				//1d. Return true;
				return true;
			} else{
				//No longer pending status for the session
				console.log('SESSION FAILED');
				this.updateSessionPending(2); //was completed
				return false;
			}
		});
	}
	async checkLoginStatus(){
		//Called on Refresh/Initial Page Load
		//1. Determine if user previously logged in
		if(this.logConsole) {
			console.log('User.js.CheckinLoginStatus(): checking login status');
		}
		if(!this.checkLocalStorageAuthorizeStatus()) {
			//Failed - not logged in.
			return false;
		}
		if(this.logConsole) {
			console.log('User.js.CheckinLoginStatus(): local auth true');
			console.log('User.js.CheckinLoginStatus(): Connecting to system to load Permissions');
		}
		//1a. Pull credentials from the system
		const sc = new SecureConnect('system.php?action=authUser','get');
		sc.setDisplaySuccessMessages(false);
		sc.setDisplayNotifications(false);
		sc.connect().then(jsonResponse =>{
			//1b. Check completed (user logged in)
			if(sc.getCompleted(jsonResponse)){
				//1c. Assign team assignments, permissions, user settings to redux
				if(this.logConsole) {
					console.log('User.js',jsonResponse);
				}
				//1d. Return true;
				return true;
			} else{
				//Not valid - logout
				if(this.logConsole){
					console.log('User.js','*** INVALID USER');
					console.log('User.js',jsonResponse);
				}
			}
		});
	}
	getURL(){
		return 'localhost:3000';
	}
	getPermittedFormOptions(editedUserSettings, type, userID){
		//Return a list of permissions that are editable by the viewer of the user
		//Run through depts/teams (based on "groupLabel")
		//Return instances (deptID's/teamID's) when the user's permissions are higher than the editedUserSettings
		//Editor's managerial permissions - Default = dept Permissions
		let permittedSet = []; //Settings (depts/teams) Editor is allowed to modify
		let deptMinLevel = ASSISTANT_LEVEL; //Starting Required Level = ASM+
		if(this.getUserID() === parseInt(userID)){
			//SElF USER - DISPLAY ALL DEPT SETTINGS - NOT JUST EDITABLE ONES
			deptMinLevel = 0; //Standard user
		}
		let editorPermissions = [];
		let setType = 'depts';
		let sectionKey = 'sectionID';
		let sortKey = 'section';
		editorPermissions = this.getManagerDepts(deptMinLevel);
		//console.log('EDITOR PERMISSIONS', editorPermissions);
		//Exception for COACHES - they need to have athlete dept available
		if(this.isSportSpecificCoach() && this.managerSystemDeptLevel(ATHLETE_DEPT_ID, ASSISTANT_LEVEL, false)){
			let basicUserPermissions = this.getManagerDepts(0);
			console.log('BASIC USER PERMISSIONS', basicUserPermissions);
			/*
			let athleteDeptPermission = basicUserPermissions.filter(item =>(parseInt(item.dept_ID) === ATHLETE_DEPT_ID))[0];
			//Set as Assistant manager - get the User selector
			athleteDeptPermission.managerLevel = ASSISTANT_LEVEL;
			editorPermissions.push(athleteDeptPermission);

			 */
		}
		let permKeys = this.getPermissionKeys(setType);
		//Create list of the user's settings
		let keys = []; //Object.keys(editedUserSettings);
		if(editedUserSettings != null){
			keys = Object.keys(editedUserSettings);
		}

		editorPermissions.forEach(set=> {
			//Run through the list of editor's available permissions to assign
			//Get the key label for dept_ID or team_ID
			const keyID = permKeys.groupKey;
			//Assign deptID or teamID to "groupID"
			const groupID = parseInt(set[keyID]);
			//convert to int
			set[sectionKey] = parseInt(set[sectionKey]);
			set['managerLevel'] = parseInt(set['managerLevel']);
			//Find instances of the viewer's dept/team within the user's permissions settings
			let ag = null;
			const activeGroup = keys.filter(k => {
				if(parseInt(editedUserSettings[k][keyID]) === groupID){
					ag = editedUserSettings[k];
					return true;
				} else{
					return false;
				}
			});
			//Found an instance - This dept/team is active
			const active = activeGroup.length > 0 ? 1: 0;
			//Set the value of the current user's permissions
			const settingValue = active === 1 ? parseInt(ag.managerLevel) : 0;
			let final = {sectionID: groupID, data: {...set, setType, active, settingValue}};
			final = {...set, setType, active, settingValue};
			permittedSet.push(final);
		});
		permittedSet.sort((a,b)=>(a[sortKey] > b[sortKey] ? 1:-1));
		return permittedSet;
	}
	getPermissionKeys(type){
		let keys={groupKey: 'dept_ID', levelKey: 'managerLevel'};
		if(type.toLowerCase().includes('teams')){
			keys={groupKey: 'sectionID', levelKey: 'managerLevel'};
		}
		return keys;
	}
	getUserID(){
		//Return the userID for the user
		//Use this to run checks to make sure the person can't edit their own permissions
		const userData = this.getUserInfo();
		return userData.userID != null ? parseInt(userData.userID) : null;
	}
	getUserUrlKey(){
		const userData = this.getUserInfo();
		return userData && userData.urlKey !== '' && userData.urlKey != null ? userData.urlKey : '';
	}
	getManagerDepts(level = ASSISTANT_LEVEL, strict = false){
		//Return a list of depts the user is a manager of
		//used when creating a new user
		return this.#getManagerListings(PERM_DEPT,'managerLevel',level, strict);
	}
	getAthleticTeams(level = ASSISTANT_LEVEL, strict){
		//Return a list of teams in which the user is a director
		//Used when creating a new actor or assigning teams to existing actor
		let teams = this.#getManagerListings(PERM_TEAM,'managerLevel',level, strict);
		return teams.sort((a,b)=>(a['team'] > b['team'] ? 1:-1));
	}
	getTeamList(level = 0){
		return this.getAthleticTeams(level,false);
	}
	getDeptDetails(sectionID){
		return this.#getSection(PERM_DEPT, sectionID);
	}
	getTeamDetails(teamID){
		if(!isNaN(parseInt(teamID))) {
			return this.#getSection(PERM_TEAM, teamID);
		} else{
			return this.#getSectionByKey(PERM_TEAM, teamID);
		}
	}
	getManagerLabel(level, athleteLabel = false){
		//Return the label based on the defined level
		const labels = athleteLabel === false ? DEPT_MANAGER_LABELS : ATHLETE_MANAGER_LABELS;
		const mgmt = labels.find(label=>parseInt(label.value) === parseInt(level));
		return mgmt != null ? mgmt.label : null;
	}
	getSystemManagerLevel(deptID){
		//Return the managerLevel based on the department ID
		const set = this.#findPermissionSet(PERM_DEPT,'system', deptID);
		if(set === false){
			return false;
		}
		return parseInt(set.managerLevel);
	}
	getSpecificDepts(deptIDList, requiredLevel, strict = false){
		const list = this.getManagerDepts(requiredLevel, strict);
		return list.filter(d => deptIDList.includes(parseInt(d.deptID)));
	}
	managerTeamLevel(teamID, requiredLevel = ASSISTANT_LEVEL, strict = false){
		return this.#checkPermissionLevel(PERM_TEAM, 'org', teamID, requiredLevel, strict)
	}
	managerSportLevel(sportID, requiredLevel = ASSISTANT_LEVEL, strict = false){
		return this.#checkPermissionLevel(PERM_TEAM,'system', sportID, requiredLevel, strict);
	}
	managerSystemDeptLevel(deptID, requiredLevel = ASSISTANT_LEVEL, strict = false){
		return deptID == null ? true : this.#checkPermissionLevel(PERM_DEPT, 'system', deptID, requiredLevel, strict);
	}
	managerSectionLevel(sectionID, requiredLevel = ASSISTANT_LEVEL, strict = false){
		return this.#checkPermissionLevel(PERM_DEPT, 'org', sectionID, requiredLevel, strict);
	}
	checkAthleteDept(deptID){
		return parseInt(deptID) === parseInt(ATHLETE_DEPT_ID);
	}
	getPermissionSectionID(sectionID, managerLevel, strict = false){
		const depts = this.getPermissions(PERM_DEPT);
		const teams = this.getPermissions(PERM_TEAM);
		console.log('DEPTS', depts);
		console.log('TEAMS', teams);
	}
	getTeamAffiliationLimits(fullViewedUserPermissions, athleteAffiliations) {
		//RETURN OVERLAP/INTERSECTIONS OF TEAMS FOR COACHES & ATHLETES ALONG WITH THE EXPIRATION FOR THE ATHLETES
		//FOR LIMITING DATE RANGES OF COACHES
		if (fullViewedUserPermissions == null || isEmpty(fullViewedUserPermissions) || athleteAffiliations == null || isEmpty(athleteAffiliations)) {
			//User Permissions, and athlete affiliations required
			return [];
		}
		const myTeams = Object.keys(this.getPermissions(PERM_TEAM));
		const teamOptions = fullViewedUserPermissions[PERM_TEAM];
		let list = [];
		myTeams.forEach(sectionID =>{
			if(this.#checkSection(teamOptions[sectionID])){
				//Team Overlap found
				//find the expiration for the affilation if it exists
				const aff = athleteAffiliations.find(aff => parseInt(aff.organizationID) === parseInt(teamOptions[sectionID].organizationID));
				const expiration = aff && aff.expirationDate != null ? aff.expirationDate : null;
				list.push({
					sectionID: parseInt(sectionID),
					organizationID: parseInt(teamOptions[sectionID].organizationID),
					expiration
				});
			}
		});
		return list;
	}
	getPermissionsIntersection(type, fullViewedUserPermissions){
		//Pull the viewing user's permissions from app state
		//Find the overlap within permission type (probably teams)
		if(fullViewedUserPermissions == null || fullViewedUserPermissions[type] == null){
			return [];
		}

		const mySectionIDs = Object.keys(this.getPermissions([type]));
		const userPermissions = fullViewedUserPermissions[type];
		let list = [];
		mySectionIDs.forEach(sectionID =>{
			if(this.#checkSection(userPermissions[sectionID])){
				list.push(parseInt(sectionID));
			}
		})
		return list;
	}
	#checkSection(section){
		return section != null && parseInt(section.active) === 1;
	}
	#checkPermissionLevel(type, systemSetting, sectionID, requiredLevel = 0, strict = false){
		const set = this.#findPermissionSet(type, systemSetting, sectionID);
		if(set === false){
			return false;
		}
		const required = parseInt(requiredLevel);
		const setLevel = parseInt(set.managerLevel);
		return (strict === false && setLevel >= required) || (strict && setLevel === required);
	}
	#findPermissionSet(type, systemSetting, sectionID){
		const systemPermissions = this.getPermissions();
		let IDKey;
		if(systemSetting === 'org'){
			IDKey = 'sectionID';
		} else if(systemSetting === 'system' && type === PERM_DEPT){
			IDKey = 'deptID';
		} else if(systemSetting === 'system' && type === PERM_TEAM){
			IDKey = 'sportID';
		} else{
			//Not within criteria. Automatically deny
			return false;
		}
		const withinGroup = systemPermissions[type] != null;
		if(withinGroup){
			const keys = Object.keys(systemPermissions[type]);
			for(let k=0; k<keys.length; k++){
				const key = keys[k]
				const set = systemPermissions[type][key];
				if(parseInt(set[IDKey]) === parseInt(sectionID)){
					return set;
				}
			}
		}
		return false;
	}
	#getManagerListings(permissionsGroup, levelKey, level = ASSISTANT_LEVEL, strict = false){
		//Return a list of dept/teams where the user is a manager or coach/athlete
		//permissionsGroup = 'depts' / 'teams';
		//console.log('getManagerListings() :: ', 'PERMGROUP', permissionsGroup, 'LEVELKEY', levelKey, 'LEVEL', level, 'STRICT?', strict);
		const requiredLevel = parseInt(level);
		let managerSettings = [];
		const userData = this.getPermissions();
		//console.log('USERDATA', userData);
		if(!userData.hasOwnProperty(permissionsGroup)){
			return managerSettings; //Return empty array - not a director/manager
		}
		const specificPermissions = userData[permissionsGroup];
		const keys = Object.keys(specificPermissions);
		keys.forEach(k =>{
			const permissionSet = specificPermissions[k];
			const setLevel = parseInt(permissionSet[levelKey]);
			if((strict && requiredLevel === setLevel) || (!strict && requiredLevel <= setLevel)){
				managerSettings.push(permissionSet);
			}
		});
		return managerSettings;
	}
	#getSection(type, sectionID){
		const permissions = this.getPermissions(type);
		return permissions[sectionID];
	}
	#getSectionByKey(type, key){
		const permissions = this.getPermissions(type);
		let returned = null;
		const keys = Object.keys(permissions);
		for(let k =0; k<keys.length; k++){
			const permissionKey = keys[k];
			const set = permissions[permissionKey];
			if(set.urlKey === key){
				returned = set;
				break;
			}
		}
		return returned;
	}
	setTypes(checkType, checkID, requiredLevel, strict){
		this.checkType = checkType;
		this.checkID = parseInt(checkID);
		this.requiredLevel = parseInt(requiredLevel);
		this.strict = !!parseInt(strict);
	}
	isAthlete(){
		const dept = this.#findPermissionSet(PERM_DEPT, 'system', ATHLETE_DEPT_ID);
		if(dept === false){
			//Dept doesn't exist. Not an athlete
			return false;
		}
		//Athlete part of athletes dept and not overridden by some other section
		const isAthlete = !(dept.override != null && dept.overrideSectionID != null);
		//console.log('IS Athlete', isAthlete, dept);
		return isAthlete;
	}
	isCoach(level = ASSISTANT_LEVEL, strict = false){
		//Must be a member of the Athletic Team Dept
		//Must have a team which they're a coach
		const strengthCoach = this.isStrengthCoach(level, strict);
		const sportCoach = this.isSportSpecificCoach(level, strict);
		return strengthCoach || sportCoach;
	}
	isStrengthCoach(level = ASSISTANT_LEVEL, strict = false){
		return this.managerSystemDeptLevel(STRENGTH_AND_CONDITIONING_DEPT_ID, level, strict);
	}
	isSportSpecificCoach(level = ASSISTANT_LEVEL, strict = false){
		return this.managerSystemDeptLevel(COACHING_STAFF_DEPT_ID, level, strict);
	}
	isTeamSportSpecificCoach(teamID, managerLevel = ASSISTANT_LEVEL, strict = false){
		return this.#checkTeamDept(teamID, managerLevel, COACHING_STAFF_DEPT_ID, strict);
	}
	isTeamStrengthCoach(teamID, managerLevel = ASSISTANT_LEVEL, strict = false){
		return this.#checkTeamDept(teamID, managerLevel, STRENGTH_AND_CONDITIONING_DEPT_ID, strict);
	}
	#checkTeamDept(teamID, managerLevel = ASSISTANT_LEVEL, deptID, strict = false){
		//Determine if the user has access to a team for a specific team based on their dept ID
		//Utilized by isTeamSportSpecificCoach & isTeamStrengthCoach
		if(teamID == null){
			return false;
		}
		teamID = parseInt(teamID);
		managerLevel = parseInt(managerLevel);
		const section = this.#getSection(PERM_TEAM, teamID);
		const {organizationID} = section;
		const managerList = this.getManagerDepts(managerLevel, strict);
		const index = managerList.findIndex(dept => parseInt(dept.organizationID) === parseInt(organizationID) && parseInt(dept.deptID) === parseInt(deptID));
		return index > -1;
	}
	initTeamSetting(){
		const user = new User();
		const teamList = user.getTeamList();
		return teamList.length === 1 ? teamList[0].sectionID : null;
	}
	getForcedPasswordReset() {
		const userData = this.getUserData();
		//return false if no userdata, otherwise return setting
		return userData.account.userInfo == null ? false : parseInt(userData.account.userInfo.resetPass) === 1;
	}
	getUserData(){
		const store = this.getStore();
		return store.FITUser;
	}
	getPermissions(type = null){
		const store = this.getFitUserStore();
		const permissions = store.account[PERM_SET];
		if(permissions != null && !isEmpty(permissions)) {
			if(type != null && permissions[type] != null){
				return permissions[type];
			} else if(type == null){
				return permissions;
			} else{
				return [];
			}
		} else{
			return [];
		}
	}
	getLoggedInStatus(){
		//Returns app state authorized user status (From Redux Store). **NOT** Local Storage
		const store = this.getFitUserStore();
		return store.authorized != null ? store.authorized : false;
	}
	getUserInfo(){
		const store = this.getFitUserStore();
		return (this.getLoggedInStatus() && store.account.userInfo != null ? structuredClone(store.account.userInfo) : {});
	}
	getDeviceAccount(returnInt = false){
		//Returns whether the user logged in is a device account
		//return store.account.userInfo.deviceAccount
		const store = this.getFitUserStore();
		const exists = this.getLoggedInStatus() && store.account.userInfo.deviceAccount != null;
		if(exists){
			const deviceValue = parseInt(store.account.userInfo.deviceAccount);
			return returnInt ? deviceValue : deviceValue > 0;
		}
		return false;
	}
	getDeviceMaster(){
		return this.getDeviceAccount(true) === DEVICE_MASTER_ACCOUNT_VALUE;
	}
	getDeviceSubAccount(){
		const store = this.getFitUserStore();
		const exists = this.getLoggedInStatus() && store.account.userInfo.altSub != null;
		return exists ?  store.account.userInfo.altSub : null;
	}
	getOnboarded(){
		const store = this.getFitUserStore();
		return this.getLoggedInStatus() && store.account.userInfo.onboarded != null ? parseInt(store.account.userInfo.onboarded) === 1 : false;
	}
	getDepts(){
		//Return a list of dept permissions
		return this.getPermissions(PERM_DEPT);
	}
	getTeams(){
		//Return a list of teams
		return this.getPermissions(PERM_TEAM);
	}
	getSettings(){
		//Return the user's settings
		const store = this.getStore();
		return store.settings;
	}
	getStatusColors(){
		return {
			success: '#3bcc00',
			info: '#00aacc',
			warning: '#e59900',
			error: '#e53a36',
			noAction: '#d9d9d9',
		}
	}
	getStore(){
		return structuredClone(ClientStore.getState());
	}
	getRouter(){
		return this.getStore().router;
	}
	getFitUserStore(){
		const FITUser = this.getStore().FITUser;
		return FITUser != null ? FITUser : {};
	}
}

export default User;
