import React, {useState, useEffect} from 'react';
import Stack from "@mui/material/Stack";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import AuthUserDropdown from "../../../../shared/widgets/AuthUserDropdown";
import {
    IconButton,
    Typography,
    Button,
    TextField,
    RadioGroup,
    Radio,
    Grid,
    FormLabel,
    FormControlLabel, Collapse, Autocomplete, Tooltip
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Logo from "../../../../shared/Logo";
import {SIDEBAR_STYLES, SIDEBAR_VARIANTS} from "@jumbo/utils/constants";
import {SEARCH_DEBOUNCE_DELAY, PRODUCTION_STATUS} from "fit/system/FITConstants";
import User from 'fit/system/User';
import UserPreferences from "fit/system/UserPreferences";
import Modal from 'fit/components/Dialogs/Modal';
import useJumboHeaderTheme from "@jumbo/hooks/useJumboHeaderTheme";
import {Cancel, CheckCircle, Login, Logout} from "@mui/icons-material";
import SecureConnect from "fit/system/SecureConnect";
import DeviceLogin from "fit/components/Devices/DeviceLogin";
import {Formik, Form} from 'formik';
import * as yup from 'yup';
import {isEmpty, getSpacing, getStatusIcon, getStatusColor} from "fit/system/UtilityFunctions";
import Alert from "fit/components/Alert";
import SecurityPin from "fit/components/User/SecurityPin";
import AlertSmall from "fit/components/AlertSmall";
const initLoginForm=()=>{
    return {
        email: '',
        pin: '',
    }
}

const Header = ({scrolled}) => {

    const QRMethod = 'QRCode';
    const emailMethod = 'email';
    const dirMethod = 'directory';


    const [adminPinModalDisplay, setAdminPinModalDisplay] = useState(false);
    const [adminPin, setAdminPin] = useState('');
    const [displayPin, setDisplayPin] = useState(false);
    const [loginDisplay, setLoginDisplay] = useState(false);


    const [adminVerified, setAdminVerified] = useState(false);

    const [method, setMethod] = useState('');

    //Directory Listings
    const [teamList, setTeamList] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState('');
    const [athleteList, setAthleteList] = useState([]);
    const [selectedAthlete, setSelectedAthlete] = useState('');

    //AthleteLogin
    const [loginForm, setLoginForm] = useState(initLoginForm);

    //ReInitialize all settings when the modal closes
    useEffect(()=>{
        if(!loginDisplay){
            initForms();
        } else{
            getTeams();
        }
    },[loginDisplay]);

    //Load the roster on team change
    useEffect(()=>{
        if(selectedTeam != null){
            getTeams(selectedTeam.value);
        } else{
            setAthleteList([]);
        }
        setSelectedAthlete(null);

    },[selectedTeam]);

    //reset pin when selected Team/Athlete changes
    useEffect(()=>{
        setAdminPin('');
    },[selectedAthlete, selectedTeam]);





    let {sidebarOptions, setSidebarOptions} = useJumboLayoutSidebar();
    const {headerTheme} = useJumboHeaderTheme();
    const user = new User();
    const deviceAccount = user.getDeviceAccount();
    const authorized = user.getLoggedInStatus();
    const userInfo = user.getUserInfo();
    const {online} = user.getFitUserStore();
    const {firstName, lastName} = userInfo;
    const onboarded = user.getOnboarded();
    const validDevice = authorized && deviceAccount;
    const altAccount = user.getDeviceSubAccount();
    const altSet = altAccount != null;
    const userPrefs = new UserPreferences();
    const directoryAvailable = [1,2].includes(parseInt(userPrefs.allowAltAccounts));

    let altAccountHeadline = '';
    if(altSet){
        altAccountHeadline = `${altAccount.firstName} ${altAccount.lastName} Signed In`
    }
    //console.log('ALT USER SET', altAccount);


    const closeDialog = () => {
        setAdminPinModalDisplay(false);
        setAdminPin('');
    }

    const setLoginMethod=(method)=>{
        setMethod(method);
        setLoginForm(initLoginForm);
        setAthleteList([]);
        resetTeam()
    }

    const logout=()=>{
        initForms();
        user.logout();
    }
    const initForms=()=>{
        setMethod('');
        setAdminPin('');
        resetTeam();
        setLoginForm(initLoginForm);
        setAdminVerified(false);
        setAthleteList([]);
    }
    const resetTeam=()=>{
        setSelectedTeam(null);
        setSelectedAthlete(null);
        setAdminPin('');
    }

    const completeDeviceLogin=()=>{
        initForms();
        setLoginDisplay(false);
    }
    const getTeams=(urlKey = '')=>{
        const teamSelected = urlKey !== '';
        const additionalParams = teamSelected ? `&teamKey=${urlKey}` : '';

        console.log('GETTING', teamSelected ? 'ATHLETES' : 'TEAM LIST');

        const sc = new SecureConnect(`devices.php?action=getAvailableTeams${additionalParams}`);
        sc.setDisplayNotifications(false);
        sc.connect().then(json =>{
            const data = sc.getData(json);
            if(teamSelected){
                setAthleteList(data)
            } else{
                setTeamList(data);
            }
        });
    }


    const pinLogin=(type = 'email')=>{

        let formData;
        if(type === 'email'){
            formData = loginForm;
        } else{
            //directory
            formData = {userKey: selectedAthlete.value, pin: adminPin};
        }
        const sc =new SecureConnect('devices.php', 'post');
        sc.setAction('loginAlt');
        sc.setFormData(formData);
        sc.connect().then(json =>{
            if(sc.getCompleted(json)){
                //successfully logged in.
                initForms();
                setLoginDisplay(false);
            }
        });
    }

    const loadAdmin = (login = false) => {
        const sc = new SecureConnect('devices.php', 'post');
        sc.setAction('loginMaster');
        sc.setFormData({
            deviceID: user.getUserID(),
            pin: adminPin,
            login: login ? 1 : 0
        })
        sc.connect().then(json => {
            console.log('ADMIN LOADED', sc.getCompleted(json));
            const completed = sc.getCompleted(json);
            if(!login && completed){
                setAdminVerified(true);
            } else{
                setAdminPinModalDisplay(false);
                initForms();
            }
        });
    }
    const logoutAlt=()=>{
        const sc = new SecureConnect('devices.php', 'post');
        sc.setAction('logoutAlt');
        sc.setFormData({logout: 1});
        sc.connect();
    }

    const handleLogin=(name, value)=>{
        let lf = {...loginForm};
        lf[name] = value;
        setLoginForm(lf);
    }





    const loginSchema = yup.object().shape({
        email: yup.string()
            .required('Email required')
            .email('Email required'),
        pin: yup.string()
            .required('Security Pin required')
            .min('4', 'Pin requires a minimum of 4 characters')
            .max('20', 'Pin exceeds maximum of 8 characters'),
    });


    let teamOptions = [{label: 'Teams Unavailable', value: ''}];
    let athleteOptions = [{label: 'Athletes Unavailable', value: ''}];
    if(!isEmpty(teamList)){
        teamOptions = [];
        teamList.forEach(t=>{
            teamOptions.push({label: `${t.shortLabel} ${t.groupName}`, value: t.urlKey});
        })
    }
    if(!isEmpty(athleteList)){
        athleteOptions = [];
        athleteList.forEach(a =>{
            //console.log(a);
            athleteOptions.push({label: `${a.firstName} ${a.lastName}`, value: a.urlKey});
        })
    }


    const mgStyle={marginTop: getSpacing('small')};
    const noAthleteSelected = selectedAthlete == null;
    const pinError = adminPin.length > 0 && adminPin.length < 4;
    const dirSelected = method === dirMethod;

    const altDivStyle={
        position: 'absolute',
        width: '100%',
        zIndex: 10000,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'bottom',
        pointerEvents: 'none',
        marginLeft: '-48px',
        minHeight: '80px',
    };
    const alertStyle={
        position: 'relative',
        pointerEvents: 'auto'
    }

    const noScrollDim = '40px';
    const scrollDim = '20px';

    const baseSX={
        maxHeight: noScrollDim,
        maxWidth: noScrollDim,
        transition: 'height 2s',
    }
    const scrollSx = {
    //    maxWidth: scrollDim,
    //    maxHeight: scrollDim,
        transform: 'scale(.8)',
    }

    const adjustedSx = scrolled ? scrollSx : {};
    const offlineStatus = 'warning';
    const InternetWarning = getStatusIcon(offlineStatus, {color: getStatusColor(offlineStatus)});



    return (
        <React.Fragment>
            {
                (
                    sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER
                    || sidebarOptions.variant === SIDEBAR_VARIANTS.TEMPORARY
                    || (sidebarOptions.variant === SIDEBAR_VARIANTS.PERSISTENT && !sidebarOptions.open)
                ) &&
                (
                    authorized && onboarded && !deviceAccount
                ) &&
                    <Tooltip title={'Open Menu'} placement={'right-end'}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            sx={{
                                ml: sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER ? -2 : 0,
                                mr: 3,
                                ...baseSX,
                                ...adjustedSx

                            }}
                            onClick={() => setSidebarOptions({open: !sidebarOptions.open})}
                        >
                            {
                                sidebarOptions?.open ? <MenuOpenIcon/> : <MenuIcon/>
                            }
                        </IconButton>
                    </Tooltip>


            }
            { //*******************Device Account Name ('Rack 01')*************************
                validDevice && <Typography variant={'h1'} sx={{color: '#FFF'}}>{firstName} {lastName}</Typography>
            }
            { //*********SCROLLING STATUS***************** DELETE WHEN DONE
               !PRODUCTION_STATUS && false && <Typography variant={'h3'} sx={{color: '#FFF'}}>_scr: {scrolled ? 1 : 0}</Typography>
            }
            <AlertSmall
                status={'warning'}
                visible={!online}
                message={'Buteo In Offline Mode'}
                tip={'Disconnected'}
            />


            <div style={altDivStyle}>
                <div style={{display: 'block', position: 'relative', top: getSpacing()}}>
                    <Alert
                        style={alertStyle}
                        status={'success'}
                        visible={validDevice && altSet}
                        headline={altAccountHeadline}
                    >
                        <Button
                            size={'small'}
                            fullWidth
                            variant={'contained'}
                            color={'secondary'}
                            onClick={()=>user.logoutAlternateAccount()}><Logout/> Logout
                        </Button>
                    </Alert>
                </div>
            </div>


            {
                sidebarOptions?.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER &&
                <Logo sx={{mr: 3}} mode={headerTheme.palette.mode ?? "light"} scrolled={scrolled}/>
            }
            <Stack direction="row" alignItems="center" spacing={1.25} sx={{ml: "auto"}}>
                {authorized &&
                    <AuthUserDropdown
                        openAdminModalFn={()=>setAdminPinModalDisplay(true)}
                        loginModalFn={()=>setLoginDisplay(true)}
                        scrolled={scrolled}
                    />
                }
            </Stack>

            <Modal
                open={loginDisplay}
                title={'Athlete Login'}
                size={'sm'}
                modalActions={
                    <Button
                        color={'secondary'}
                        variant={'contained'}
                        onClick={()=>setLoginDisplay(false)}
                    ><Cancel/> Cancel</Button>
                }
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <RadioGroup>
                            <FormLabel>Select your method</FormLabel>
                            <FormControlLabel control={<Radio color={'primary'} checked={method === QRMethod} onClick={()=>setLoginMethod(QRMethod)}/>} label={'QR Code'}/>
                            <FormControlLabel control={<Radio color={'primary'} checked={method === emailMethod} onClick={()=>setLoginMethod(emailMethod)}/>} label={'Email & Pin'}/>
                            {
                                directoryAvailable ?
                                <FormControlLabel control={<Radio color={'primary'} checked={method === dirMethod} onClick={()=>setLoginMethod(dirMethod)}/>} label={'Athlete Directory & Pin'}/>
                                : null
                            }
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <Collapse in={method === QRMethod}>
                            <DeviceLogin
                                altAccount={true}
                                requestCode={method === QRMethod}
                                successFn={()=>completeDeviceLogin()}
                            />
                        </Collapse>
                        <Collapse in={method === emailMethod}>


                            <Formik
                                initialValues={loginForm}
                                validationSchema={loginSchema}
                                onSubmit={()=>pinLogin('email')}
                                enableReinitialize={true}
                            >
                            {({errors, touched}) => (
                            <Form>


                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={'Email'}
                                        autoComplete={'off'}
                                        value={loginForm.email}
                                        name={'email'}
                                        onChange={(e)=>handleLogin(e.target.name, e.target.value)}
                                        error={errors.email && touched.email}
                                        helperText={errors.email || ' '}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SecurityPin
                                        fullWidth
                                        displayPin={displayPin}
                                        delay={SEARCH_DEBOUNCE_DELAY}
                                        autoComplete={'off'}
                                        value={loginForm.pin}
                                        onChange={(value)=>handleLogin('pin', value)}
                                        setDisplayPin={()=>setDisplayPin(!displayPin)}
                                        error={errors.pin && touched.pin}
                                        helperText={errors.pin || ' '}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        fullWidth
                                        color={'primary'}
                                        variant={'contained'}
                                        type={'submit'}
                                    >Login</Button>
                                </Grid>
                            </Grid>
                            </Form>
                            )}
                            </Formik>
                        </Collapse>
                        <Collapse in={dirSelected}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormLabel>Select Team</FormLabel>
                                    <Autocomplete
                                        disablePortal={false}
                                        fullWidth={true}
                                        autoSelect={true}
                                        clearOnEscape={true}
                                        label={'Team'}
                                        onChange={(e, value)=>setSelectedTeam(value)}
                                        value={selectedTeam}
                                        disabled={isEmpty(teamList)}
                                        placeholderText={'Select Team'}
                                        options={teamOptions}
                                        renderInput={(params)=> <TextField {...params} />}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormLabel>Select Athlete</FormLabel>
                                    <Autocomplete
                                        disablePortal={false}
                                        fullWidth={true}
                                        autoSelect={true}
                                        clearOnEscape={true}
                                        label={'Athlete'}
                                        onChange={(e, value)=>setSelectedAthlete(value)}
                                        value={selectedAthlete}
                                        disabled={isEmpty(athleteList)}
                                        placeholderText={'Select Athlete'}
                                        options={athleteOptions}
                                        renderInput={(params)=> <TextField {...params} />}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <div style={mgStyle}>
                                        <SecurityPin
                                            fullWidth
                                            displayPin={displayPin}
                                            delay={SEARCH_DEBOUNCE_DELAY}
                                            autoComplete={'off'}
                                            value={adminPin}
                                            onChange={(value)=>setAdminPin(value)}
                                            setDisplayPin={()=>setDisplayPin(!displayPin)}
                                            error={pinError}
                                            disabled={selectedAthlete == null}
                                            helperText={pinError ? 'Invalid Pin' : ' '}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        fullWidth
                                        disabled={pinError || adminPin === '' || noAthleteSelected}
                                        color={'primary'}
                                        variant={'contained'}
                                        onClick={()=>pinLogin('directory')}
                                    >Login</Button>
                                </Grid>
                            </Grid>
                        </Collapse>

                    </Grid>


                </Grid>
            </Modal>


            <Modal
                open={adminPinModalDisplay}
                title={'Enter Administrator Pin'}
                closeFunction={()=>setAdminPinModalDisplay(false)}
                size={'sm'}
                modalActions={
                    <React.Fragment>
                        <Button
                            color={'primary'}
                            variant={'contained'}
                            disabled={pinError || adminPin === '' || adminVerified}
                            onClick={()=>loadAdmin(false)}
                        >
                            <CheckCircle/> Submit
                        </Button>
                        <Button color={'secondary'} variant={'contained'} onClick={()=>closeDialog()}>
                            <Cancel/> Cancel
                        </Button>
                    </React.Fragment>
                }
            >

                <SecurityPin
                    fullWidth
                    delay={SEARCH_DEBOUNCE_DELAY}
                    autoComplete={'off'}
                    label={'Admin Pin'}
                    displayPin={displayPin}
                    value={adminPin}
                    onChange={(value)=>setAdminPin(value)}
                    setDisplayPin={()=>setDisplayPin(!displayPin)}
                    error={pinError}
                    helperText={pinError ? 'Invalid Pin' : ' '}
                />
                <Collapse in={adminVerified}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Button
                                fullWidth
                                color={'primary'}
                                variant={'contained'}
                                onClick={()=>loadAdmin(true)}
                            >
                                <Login/> Login As Administrator
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                fullWidth
                                color={'secondary'}
                                variant={'contained'}
                                onClick={()=>logout()}
                            >
                                <Logout/> Logout Device
                            </Button>
                        </Grid>
                    </Grid>
                </Collapse>
            </Modal>


        </React.Fragment>
    );
};

export default Header;